import React from 'react';
import {
  Header,
  HeaderName,
  SkipToContent,
} from 'carbon-components-react/lib/components/UIShell';
import { Link } from 'react-router-dom';

const PageHeader = () => (
  <Header aria-label="Rube Apps">
    <SkipToContent />
    <HeaderName element={Link} to="/" prefix="Rube">
      Apps
    </HeaderName>
  </Header>
);

export default PageHeader;
