import React from 'react';
import {
  Button,
  Form,
  FormGroup,
  TextInput,
} from 'carbon-components-react';

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      invalid: false
    }
  }

  render() {
    const checkLogin = () => {
      this.setState({ invalid: false, loading: true })
      setTimeout(function() {
        this.setState({invalid: true, loading: false})
      }.bind(this), 1000)
    }
    return (
      <div className="bx--grid bx--grid--full-width landing-page">
        <div className="bx--row">
          <div className="bx--col-lg-16" style={{ 'height': 'calc(100vh - 3rem)', 'padding': '20px' }}>
            <div className="bx--col-lg-8" style={{ 'margin': '20px auto 0 auto' }}>
              <div style={{ 'text-align': 'center' }}>
              <h1 className="landing-page__heading">
                Rube Portal
              </h1>
              <p style={{ marginTop: 10, marginBottom: 10 }}>Login to access the dashboard and associated services.</p>
              </div>
              <Form>
                <FormGroup>
                  <TextInput
                    id="email"
                    type="email"
                    invalidText="Invalid email."
                    labelText="Email"
                    placeholder="john.foster@example.org"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <TextInput
                    id="password"
                    type="password"
                    labelText="Password"
                    placeholder="•••••••••••"
                    invalid={this.state.invalid}
                    invalidText='Unable to login using the supplied credentials.'
                    required
                  />
                </FormGroup>
                <div style={{float:'right'}}>
                  <Button
                    kind="secondary"
                    href="mailto:support@rubeapps.com"
                  >
                    Contact Us
                  </Button>
                  <Button
                    kind="primary"
                    type="submit"
                    style={{marginLeft: 10 }}
                    onClick={checkLogin}
                    disabled={this.state.loading}
                  >
                    Login
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LandingPage;
